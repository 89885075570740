import resourceTypes from 'enums/ResourceType'
import theme from 'utils/theme'

import BookIcon from 'assets/resource-type-icons/icon-book.svg'
import OnlineResourceIcon from 'assets/resource-type-icons/icon-online-resource.svg'
import PeopleIcon from 'assets/resource-type-icons/icon-people.svg'
import BlogIcon from 'assets/resource-type-icons/icon-blog.svg'
import PodcastIcon from 'assets/resource-type-icons/icon-podcast.svg'


const colorForResourceType = (type) => {
  switch (type) {
    case resourceTypes.BOOK: return `rgb(144,204,244,0.24)`
    case resourceTypes.ONLINE_RESOURCE: return `rgb(243,210,80,0.24)`
    case resourceTypes.PERSON: return `rgb(247,136,136,0.24)`
    case resourceTypes.BLOG_POST: return `rgb(247,136,136,0.24)`
    case resourceTypes.PODCAST: return `rgb(243,210,80,0.24)`
    default: return theme.colors.black20
  }
}

const iconForResourceType = (type) => {
  switch (type) {
    case resourceTypes.BOOK: return BookIcon
    case resourceTypes.ONLINE_RESOURCE: return OnlineResourceIcon
    case resourceTypes.PODCAST: return PodcastIcon
    case resourceTypes.PERSON: return PeopleIcon
    case resourceTypes.BLOG_POST: return BlogIcon
    default: return null
  }
}

export default {
  colorForResourceType: colorForResourceType,
  iconForResourceType: iconForResourceType,
}
