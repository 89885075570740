import React from "react"
import { graphql } from 'gatsby'
import { get } from 'lodash'

import Layout from "layout/layout"
import SEO from "components/seo"
import PersonView from 'components/cards/PersonView'

const PagePadding = {
  padding: `0 0 32px 0`,
}

const ContentBlockStyle = {
  display: `flex`,
  flexWrap: `wrap`,
  width: `100%`,
  padding: `0 0 40px 0`,
}

class DesignerPage extends React.Component {

  constructor(props) {
    super(props);

    this.lastName = this.lastName.bind(this)
    this.sortAlphabetically = this.sortAlphabetically.bind(this)
  };

  lastName(person) {
    const name = person.name

    const splitNames = name.split(" ")

    const comparison = splitNames[splitNames.length - 1]

    return comparison
  }

  sortAlphabetically(designers) {
    return designers.sort(function(a, b) {
      const aLastName = this.lastName(a)
      const bLastName = this.lastName(b)

      return aLastName.localeCompare(bLastName)
    }.bind(this))
  }

  render() {

    const allDesigners = get(this, 'props.data.allContentfulPerson.edges').map(edge => ( edge.node ))
    const designers = this.sortAlphabetically(allDesigners)

    return (
      <Layout>
        <div className="container">
          <div className="row">
            <div className="col-12" style={PagePadding}>
              <SEO title={`Resources`} />
              <h1>Designers</h1>
              <p>Don't know what else more to say about these people than "give them a follow!" They're all involved in the design community in some way or another, and most have books, blogs, and content channels that are great resources to learn from. All of them have professional work that speaks for themselves.</p>
            </div>
            <div style={ContentBlockStyle}>
              {
                designers.map(designer => (
                  <PersonView key={designer.id} person={designer} />
                ))
              }
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default DesignerPage

export const pageQuery = graphql`
  query PeopleQuery {
    allContentfulPerson {
      edges {
        node {
          id
          resourceType
          name
          tags
          website
          youtube
          medium
          linkedin
          dribbble
          instagram
          github
          twitter
        }
      }
    }
  }
`
