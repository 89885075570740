import React from "react"
import theme from 'utils/theme'
import { css } from 'emotion'
import socialType from 'enums/SocialType'
import socialIcons from 'utils/SocialIcons'
import resourceTypeDetails from 'utils/ResourceTypeDetails'

const CardWrapper = {
  margin: `0 0 40px 0`,

  [`${theme.responsiveKeys.mobile}`]: {
    margin: `0 0 24px 0`,
  },
}

const CardStyle = {
  padding: `16px`,
  boxShadow: `rgba(35, 41, 47, 0.06) 0px 4px 20px 0px`,
  borderRadius: `6px`,

  [`${theme.responsiveKeys.mobile}`]: {
    boxShadow: `rgba(35, 41, 47, 0.06) 0px 2px 16px 0px`,
  },
}

const ResourceTypeStyle = {
  display: `flex`,
  justifyContent: `center`,
  alignItems: `center`,
  width: `56px`,
  height: `56px`,
  borderRadius: `40px`,
  backgroundColor: theme.colors.white,
  margin: `0`,

  // boxShadow: `0px -4px 6px 0px rgba(35,41,47,0.03)`,

  [`${theme.responsiveKeys.mobile}`]: {
    width: `48px`,
    height: `48px`,
    margin: `0`,
  },
}

const ResourceTypeInnerContainerStyle = {
  display: `flex`,
  justifyContent: `center`,
  alignItems: `center`,
  width: `48px`,
  height: `48px`,
  borderRadius: `28px`,
}

const ResourceTypeImageStyle = {
  margin: 0,
  padding: 0,
}

const NameStyle = {
  margin: `16px 0 0 0`,
}

const SocialIconsContainer = {
  display: `flex`,
  flexWrap: `wrap`,
  margin: `0`
}

const SocialIconStyle = {
  padding: `16px 24px 0 0`,
}

const SocialIconImageStyle = {
  margin: `0`,
  padding: `0`,
}

const TagContainerStyle = {
  display: `flex`,
  flexWrap: `wrap`,
  margin: `8px 0 0 0`,
}

const TagStyle = {
  display: `flex`,
  justtifyContent: `center`,
  margin: `8px 8px 0 0`,
  padding: `6px 12px`,
  borderRadius: `16px`,
  backgroundColor: theme.colors.black20,
  width: `fit-content`,
  height: `fit-content`,
}

const TagTextStyle = {
  fontSize: `14px`,
  lineHeight: `1.2em`,
  fontWeight: theme.fontWeight.regular,
  margin: 0,
  padding: 0,
  color: theme.colors.black80,
}

class PersonView extends React.Component {

  filterSocialOutlets(outlets) {
    return outlets.filter(outlet => (
      (outlet.value !== null) && outlet.value !== "default"
    ))
  }

  filterTags(tags) {
    if (typeof(tags) === "undefined") {
      return []
    }

    return tags.filter(tag => (
      tag !== "default"
    ))
  }

  render() {
    const { id,
            resourceType,
            name,
            tags,
            website,
            youtube,
            medium,
            linkedin,
            dribbble,
            instagram,
            github,
            twitter } = this.props.person

    const socialOutlets = [
      {
        type: socialType.WEBSITE,
        value: website,
      },
      {
        type: socialType.YOUTUBE,
        value: youtube,
      },
      {
        type: socialType.MEDIUM,
        value: medium,
      },
      {
        type: socialType.LINKEDIN,
        value: linkedin,
      },
      {
        type: socialType.DRIBBBLE,
        value: dribbble,
      },
      {
        type: socialType.INSTAGRAM,
        value: instagram,
      },
      {
        type: socialType.GITHUB,
        value: github,
      },
      {
        type: socialType.TWITTER,
        value: twitter,
      },
    ];

    const availableSocialOutlets = this.filterSocialOutlets(socialOutlets)
    const availableTags = this.filterTags(tags || [])

    const resourceTypeBackgroundColor = resourceTypeDetails.colorForResourceType(resourceType.toUpperCase())
    const resourceTypeIcon = resourceTypeDetails.iconForResourceType(resourceType.toUpperCase())

    return (
      <div className='col-4 col-12-sm'>
        <div className={css(CardWrapper)}>
          <div className={css(CardStyle)}>
            <div className={css(ResourceTypeStyle)}>
              <div style={{...ResourceTypeInnerContainerStyle, ...{backgroundColor: resourceTypeBackgroundColor}}}>
                <img style={ResourceTypeImageStyle} src={resourceTypeIcon} alt={resourceType} />
              </div>
            </div>
            <h5 style={NameStyle}>{name}</h5>
            <div style={SocialIconsContainer}>
              {
                availableSocialOutlets.map(outlet => (
                  <a style={SocialIconStyle} href={`${outlet.value}?ref=deconstructingdesign`} key={outlet.type}><img style={SocialIconImageStyle} src={socialIcons(outlet.type)} alt={`${outlet.type}`} /></a>
                ))
              }
            </div>
            <div style={TagContainerStyle}>
              {
                availableTags.map(tag => (
                  <div style={TagStyle} key={tag}>
                    <p style={TagTextStyle}>{tag}</p>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PersonView


    // const socialOutlets = [
    //   {
    //     type: socialOutletType.WEBSITE,
    //     website: website,
    //   },
    //   {
    //     type: socialOutletType.YOUTUBE,
    //     youtube: youtube,
    //   },
    //   {
    //     type: socialOutletType.MEDIUM,
    //     medium: medium,
    //   },
    //   {
    //     type: socialOutletType.LINKEDIN,
    //     linkedin: linkedin,
    //   },
    //   {
    //     type: socialOutletType.DRIBBBLE,
    //     dribbble: dribbble,
    //   },
    //   {
    //     type: socialOutletType.INSTAGRAM,
    //     instagram: instagram,
    //   },
    //   {
    //     type: socialOutletType.GITHUB,
    //     github: github,
    //   },
    //   {
    //     type: socialOutletType.TWITTER,
    //     twitter: twitter,
    //   },
    // ];