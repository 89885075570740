import socialType from 'enums/SocialType'
import DribbbleIcon from 'assets/social-icons/icon-dribbble.svg'
import GitHubIcon from 'assets/social-icons/icon-github.svg'
import InstagramIcon from 'assets/social-icons/icon-instagram.svg'
import LinkedinIcon from 'assets/social-icons/icon-linkedin.svg'
import MediumIcon from 'assets/social-icons/icon-medium.svg'
import TwitterIcon from 'assets/social-icons/icon-twitter.svg'
import WebsiteIcon from 'assets/social-icons/icon-website.svg'
import YouTubeIcon from 'assets/social-icons/icon-youtube.svg'

export default function mapOutletTypeToIcon(type) {
  switch (type) {
    case socialType.WEBSITE:
      return WebsiteIcon
    case socialType.YOUTUBE:
      return YouTubeIcon
    case socialType.MEDIUM:
      return MediumIcon
    case socialType.LINKEDIN:
      return LinkedinIcon
    case socialType.DRIBBBLE:
      return DribbbleIcon
    case socialType.INSTAGRAM:
      return InstagramIcon
    case socialType.GITHUB:
      return GitHubIcon
    case socialType.TWITTER:
      return TwitterIcon
    default:
      return WebsiteIcon
  }
}
