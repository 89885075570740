
const SocialType = Object.freeze({
  WEBSITE: 'WEBSITE',
  YOUTUBE: 'YOUTUBE',
  MEDIUM: 'MEDIUM',
  LINKEDIN: 'LINKEDIN',
  DRIBBBLE: 'DRIBBBLE',
  INSTAGRAM: 'INSTAGRAM',
  GITHUB: 'GITHUB',
  TWITTER: 'TWITTER',
})

export default SocialType
